import MoleculeStructure from "views/RDkit/MoleculeStructure";
import Plus from '../../../assets/images/icons/plus.png'
// import Implies from "../../../assets/images/icons/implies.png"
import React from 'react'
import {useState,useEffect} from 'react'
import { useCenteredTree2 } from "./helpers";
import { Typography } from "@mui/material";
import Implies from '../../../assets/images/icons/implies-if-mathematical-sign.png'
import Logo from "./logo"



const Tdstyle={
  textWrap:'nowrap'
}

const refine1 = (atta) => {
  return new Promise((resolve) => {
    let OBJ = [];

    const Refined = async (atta) => {
      let Ch = [];
      if (atta.children !== undefined) {
        await Promise.all(atta.children.map(async (data) => {
          Ch.push(data.smile);
          if(data.children!=undefined){
          await Refined(data.children[0]);}
        }));
      }

      OBJ.push({ smile: atta.smile, child1: Ch, procedure: atta.procedure[0] });
    };

    Refined(atta).then(() => {
      resolve(OBJ);
    });
  });
};
  
  

const refine2 = (nData) => {
  return new Promise((resolve) => {
    let OBJ = [];

    const Refined = async (nData) => {
      let Ch = [];
      if (nData.children !== undefined) {
        await Promise.all(nData.children.map(async (data) => {
          Ch.push(data.smile);
          if(data.children!=undefined){
          await Refined(data);}
        }));
      }

      OBJ.push({ smile: nData.smile, child1: Ch });
    };

    Refined(nData).then(() => {
      resolve(OBJ.reverse());
    });
  });
};








 const DownloadPdf = ({tdata, template })=>{

let OBJECT =[]
const [DownCollection, setDownCollection]= useState([])
 


const ConvertPath =async()=>{
if (template=='retrosynthesis'){

await Promise.all(tdata.map(async (data) => {
    const Splitpath = await refine1(data.path.children[0]);
    OBJECT.push({
      idx: data.idx,
      smile: data.path.smile,
      pathcost: data.path.path_cost,
      pathyield: data.path.path_yield,
      numsteps: data.path.num_steps,
      splitarr: Splitpath
    });
  }));
setDownCollection(OBJECT)
 }
 else{
await Promise.all(tdata.map(async (data) => {
  const Splitpath = await refine2(data.path);
  OBJECT.push({
    idx: data.idx,
    smile: data.path.smile,
    numsteps: data.path.num_steps,
    splitarr: Splitpath
  });
}));

setDownCollection(OBJECT)
}

 }

console.log(DownCollection)

useEffect(()=>{
    ConvertPath()
},[])

 return (
<div id='DowndataM' >
<div  style={{textAlign:'center'}}>
  <img   src= {Logo}   height='80px' alt="Rebolt" style= {{position:'relative', top:'20px',marginBottom:'30px'}} />
  </div>
{
  DownCollection.sort(function(a,b){return a.idx - b.idx}).map((treedata,index)=>{
    
return(
<div className="page_break" key={index}>  
<Typography sx={{marginLeft:'30px',marginTop:'30px',fontSize:'20px', fontWeight:'bold'}}><b>Path Id: {treedata.idx+1}</b></Typography>  
<div>



{ (template=='retrosynthesis') ?
  <table style= {{marginLeft:'30px',marginTop:'30px',marginBottom:'30px'}} >
  <tbody>
   <tr><td style={Tdstyle}><b  style={{marginRight:'30px'}}>Target Molecule:</b></td><td>{treedata.smile}</td></tr>
   <tr><td style={Tdstyle}><b>Estimated Cost:</b></td><td>${treedata.pathcost}</td></tr>
   <tr><td style={Tdstyle}><b>Estimated Yield:</b></td><td>{treedata.pathyield}</td></tr>
   <tr><td style={Tdstyle}><b>number of steps:</b></td><td>{treedata.numsteps}</td></tr>
   </tbody> 
   </table>
   :
   <table style= {{marginLeft:'30px',marginTop:'30px',marginBottom:'30px'}} >
  <tbody>
   <tr><td style={Tdstyle}><b style={{marginRight:'30px'}}>Target Molecule: </b></td><td>{treedata.smile}</td></tr>
   <tr><td style={Tdstyle}><b>number of steps:</b></td><td>{treedata.numsteps}</td></tr>
   </tbody> 
   </table>
   } 
</div>

{

treedata.splitarr.map((data,index) =>{

return(
<div className="path" key={`${data.smile}${index}`} style={{marginTop:'30px'}}>  
<div className="step" style={{marginLeft:'30px'}} ><h3>Step {index+1}</h3>
<div  className='SML' style={{display:'flex',marginLeft:'20px', flexDirection:'row', justifyContent: 'space-around',flexWrap: 'wrap'}} > 
{ 
   data.child1.map((Mol,index)=>{
   return (
   <React.Fragment key={index}>
    <MoleculeStructure width={150} height={100} svgMode structure={Mol}/>
    {(index < data.child1.length-1) &&
    <img  src={Plus} width='30px' height='30px' alt='plus'style= {{position:'relative',top:'40px'}}/> }
    </React.Fragment>)
   })

   }
   <img src={Implies} width='50px' height='30px' alt ='implies' style= {{position:'relative',top:'45px'}}/> 
   <MoleculeStructure width={150} height={100} svgMode structure={data.smile}/>
    
</div>
</div>
<div>
  { (template=='retrosynthesis') &&
   <p style={{textAlign: 'justify',marginLeft:'30px'}}><span style={{fontWeight:'bold'}}>Procedure:</span>{data.procedure}</p>  
  }
</div>
</div>
 ) })
}
</div>
)})}
</div> 
 )
 }


 
 
  
 

 export default DownloadPdf