import React, {useContext,useState}from 'react'
import { Snackbar,Stack,Alert } from '@mui/material'
import { createContext } from 'react'

const  Snackcontext = createContext()

export const useSnackbar = () => useContext(Snackcontext)

 export const Snackprovider = ({children})=>{
    const [Sncktext,setSncktext] =  useState('')
    const [Snackopen,setSnackopen] = useState(false)


    const Showsnack= (msg)=>{
        setSncktext(msg)
        setSnackopen(true)
    }
  
    const Closesnack = ()=>{
        setSncktext('')
        setSnackopen(false)
    }
  
  return (
  <Snackcontext.Provider value={{Showsnack,Closesnack}}>

   {
    children
   }
   <Stack spacing={2} sx={{ width: '100%'}}>
                <Snackbar open={Snackopen} autoHideDuration={1000} onClose={Closesnack} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} >
                    <Alert severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
                        {Sncktext}
                    </Alert>
                </Snackbar>
    </Stack>
    </Snackcontext.Provider>
   
  )
}


export default useSnackbar