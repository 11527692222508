import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Card, Divider, Grid, AppBar, Toolbar, Button, Typography, ImageList, ImageListItem, Alert, IconButton, Tooltip,  Snackbar, TableContainer } from '@mui/material';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import MoleculeStructure from "views/RDkit/MoleculeStructure";
import Implies from '../../../assets/images/icons/arrow2.png'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';

//firebase imports
import { db } from 'index';
import { query, doc, getDoc } from '@firebase/firestore';
import { Add, AddOutlined, ArrowBackOutlined, ArrowForward, ArrowForwardOutlined, CopyAllOutlined,Download } from '@mui/icons-material';
import ExampleCanvas from 'views/RDkit/ExampleCanvas';
import SubCard from 'ui-component/cards/SubCard';
import Subappbar from 'ui-component/subappbar/Subappbar';
import { useEffect } from 'react';
import { textAlign } from '@mui/system';

const ImpurityPredictionOutputComp= ({eid,Agsc})=> {
    var navigate = useNavigate()
    const [arrayData, setArrayData] = React.useState([])
    const [isLoading, setLoader] = React.useState(true)
    const [Inputs,setInputs]= React.useState({})
    const [smile, setSmile] = React.useState('')
    // const { eid } = useParams();
    const [dataload,setdataload] = React.useState(false)
    const [smilesCopysnack,setsmilesCopysnack] = React.useState(false)
    const [Proid,setProid] =  React.useState('')
    const [flowRedirect,setflowRedirect] = React.useState(false)
    
    const Loc = useLocation()
    const DwnldFun = ()=>{
        
        const Data = [ ['SNO','Predicted Impurities','Possible Mechanism','Feasibility Score','Similarity score'],]
        
       arrayData.map((data,index)=>{
          Data.push([index+1,data.prd_smiles,data.modes_name,Number(data.avg_insp_score).toFixed(3),Number(data.similarity_to_major).toFixed(3)])
       })
      
     console.log(Data)
    
   
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(Data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'Rebolt/ImpurityPrediction.xlsx');
      }
    
     console.log(eid)
   
    const getoutput = () => {
       
        const Document = query(doc(db, 'impurities', eid))
        getDoc(Document)
            .then(res => {
                setInputs({Reactant:res.data().reactant,Product:res.data().product})
                setArrayData([...res.data().output])
                setProid(res.data().project_id)
               
               }).then(setdataload(true))
                
            }

   
    useEffect(()=>{
        if(Loc.state?.flow){
            setflowRedirect(true)
        }
        if(dataload){
        setLoader(false)
        }
    },[arrayData])
    
    


    useEffect(() => {
        getoutput();
    }, [])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.Font,
            padding:'10px'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding:'10px'
        },
    }));

   
    const iconstyles = {
        color: 'white'
    }
    const iconstyles2 = {
        color: "#323259"
    }

    
    return (
        <>
            <Subappbar
                navigate1={flowRedirect ?   () => navigate(-1):() => navigate( `/Experiments/${Proid}`,{state:{template:'impurities'}})}
                navigate3={() => navigate('/ImpurityPredictionIn')}
                icon1={!Agsc && <ArrowBackOutlined sx={iconstyles2} />}
                crexpicon={!Agsc &&<AddOutlined sx={iconstyles2} />}
                title="Impurity Prediction"
            />
          
            <MainCard>
            <AppBar position="static" sx={{ marginBottom:'30px', backgroundColor: 'white' }}>
            <Toolbar sx={{flexDirection: 'row-reverse'}}>           
            <Tooltip title='Download'>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    // onClick={() => {setDwndiag(true) }}
                                    sx={{position:'absolute'}}
                                    onClick={DwnldFun}
                                >
                                    <Download sx={iconstyles2} />
                                </IconButton>
            </Tooltip>
            </Toolbar> 
            </AppBar>
                <Paper>
                    <Card>
                        {isLoading ?
                            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="secondary" />
                            </Stack>


                            :
                            <>
                                {arrayData.length === 0 ?
                                    <Alert severity="error" sx={{ textAlign: 'center' }}>No data found</Alert>
                                    :
                                    <>
                                    <div style={{display:'flex',justifyContent:'space-around', marginBottom:'20px'}}>
                                          <MoleculeStructure width={150} height={100} svgMode structure={Inputs.Reactant}/>
        
                                          <img src={Implies} style={{width:'150px',height:'50px', marginTop:'35px'}}/>
                                          
                                          <MoleculeStructure width={150} height={100} svgMode structure={Inputs.Product}/>
                                    </div>
                                    <TableContainer sx={{ minHeight: '60vh' }} elevation='4'>
                                        <Table>
                                            <TableHead >
                                                <TableRow sx={{padding:'10px',boxShadow:'0px 1px 5px 0px #323259'}} >
                                                    <StyledTableCell>Sno</StyledTableCell>
                                                    <StyledTableCell>Predicted impurities</StyledTableCell>
                                                    <StyledTableCell >Possible mechanisms</StyledTableCell>
                                                    <StyledTableCell>Feasibility Score </StyledTableCell>
                                                    <StyledTableCell>Similarity score</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {
                                            arrayData.map((data,index)=>{

                                            return(
                                            <TableRow key={index} sx={data.modes_name=="Normal reaction" ? {backgroundColor:'#e8ecf3'}:{background:'none'}}>
                                                    <StyledTableCell>{index+1}</StyledTableCell>
                                                    <StyledTableCell sx={{display:'flex', justifyContent:'center', position:'relative'}}>
                                                    {data.modes_name=="Normal reaction" &&  <div  style={{position:'absolute', width:'180px',height:'140px', backgroundColor:'rgb(194 203 218 / 36%)'}}></div> }
                                                    <div  style={{position:'absolute', width:'180px', display:'flex', justifyContent:'flex-end'}}>
                                                    <Tooltip title='copy Smiles' sx={{opacity:'0.1', fontSize: '1.25rem', WebkitAppearance:'none',}} >        
                                                          <ContentCopyRoundedIcon   
                                                           onClick={()=>{navigator.clipboard.writeText(`${data.prd_smiles}`);
                                                           setsmilesCopysnack(true) }}/>
                                                    </Tooltip></div>
                                                         <ExampleCanvas structure={data.prd_smiles} />
                                                    
                                                    </StyledTableCell>
                                                    <StyledTableCell>{data.modes_name}</StyledTableCell>
                                                    <StyledTableCell>{Number(data.avg_insp_score).toFixed(3)}</StyledTableCell>
                                                    <StyledTableCell>{Number(data.similarity_to_major).toFixed(3)}</StyledTableCell>
                                            </TableRow>
                                            )
                                            })
                                            }
                                            </TableBody>
                                        </Table>
                                      
                                      </TableContainer>
                                    </>

                                }

                            </>
                        }

                    </Card>
                </Paper>
            </MainCard>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={smilesCopysnack} autoHideDuration={2000} onClose={() => { setsmilesCopysnack(false) }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                    <Alert onClose={() => setsmilesCopysnack(false)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
                        SMILES copied to clipboard
                    </Alert>
                </Snackbar>
            </Stack>
            
        </>
    );
}

export default ImpurityPredictionOutputComp;

