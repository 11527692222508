
// material-ui
import { ArrowDownward, Expand } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography,LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import ExampleCanvas from 'views/RDkit/ExampleCanvas';
import SubCard from '../SubCard';
import {useState,useEffect} from 'react'
import { getStorage, ref, getDownloadURL } from '@firebase/storage'
import { getApp } from 'firebase/app'
import { useTheme } from '@mui/styles';



// ==============================|| SKELETON - TOTAL INCOME DARK/LIGHT CARD ||============================== //









const TotalIncomeCard = ({
    outputid,
    outputdata,
    updateobject,
    template
}) => {


    const [paths, setpaths] = useState({})
    const [expand,setexpand] = useState(false)

    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp);


    const theme = useTheme()
    
    
    const customestyles = {
        borderRadius: '0px',
        borderTop: "3px solid",
        borderColor: theme.palette.secondary.dark,
        padding: '2px'
    }
    const iconstyle2 = {
        color: "#323259"
    }



    
    const Intermediatejson = async ({path}) => {
    
            
        const pathReference = ref(storage, path);
        const downloadURL = await getDownloadURL(pathReference)
        const response = await fetch(downloadURL).catch((err) => console.log(err));
        console.log(response)
        const interjson = await response.json();
        console.log(interjson)
        setpaths({...interjson})
      }
      console.log(paths)
      
      console.log(outputdata.status)
      useEffect(()=>{
        if(outputdata.status=='completed'||outputdata.status=="100% completed"){
        Intermediatejson({path:outputdata.output.datainfo.outputFilePath})}
      },[outputdata.status] )


console.log(outputdata)
console.log(paths)
return(
    <Card sx={customestyles}>
       {/* {(template == 'retrosynthesis') ? */}
        <Accordion expanded='true'>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header" sx={{justifyContent: 'space-between'}}>
                <ExampleCanvas  structure={outputid} />
            </AccordionSummary>
           
            <AccordionDetails>
            {expand && 
                (paths=={}) ? <LinearProgress color="secondary" />
                    : Object.entries(paths).map((entry) =>
                        <SubCard title={entry[0]} sx={{ padding: '0px' }}>
                           
                            <Grid container spacing={2}>
                                {
                                    entry[1].path.reactants.map((data) => <>
                                        <Grid item xl={6} md={6} sm={12} xs={12}>
                                            <Card elevation={2} 
                                            sx={{border:'solid', borderWidth:'1.5px', borderColor:`${entry[1].path.reactant_colors[ entry[1].path.reactants.indexOf(data)]}`}}
                                            >
                                                <ExampleCanvas 
                                                    structure={data} />
                                            </Card>
                                        </Grid>
                                    </>)
                                }
                            </Grid>
                            <Box sx={{display:'flex',mt:2}}>
                                {/* <Typography variant='subtitle1' sx={{flexGrow:1}} color='text.secondary'>Click add to preview</Typography> */}
                                <Button variant='outlined' sx={iconstyle2} onClick={()=>updateobject({data:entry[1].path,parid:outputdata.parentnodeid,pathid:outputdata.pathid})}>Preview</Button>

                            </Box>
                        </SubCard>
                    )
                }
            </AccordionDetails>
            </Accordion>
        {/* </Accordion>  
        :
        <Accordion expanded='true'>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <ExampleCanvas  structure={outputid} />
            </AccordionSummary>
            <AccordionDetails>
                { outputdata.status === 'completed' &&
                    Object.entries(outputdata.output).map((entry) =>
                        <SubCard title={entry[0]} sx={{ padding: '0px' }}>
                           
                            <Grid container spacing={2}>
                                {
                                    entry[1].path.reacts.map((data) => <>
                                        <Grid item xl={6} md={6} sm={12} xs={12}>
                                            <Card elevation={2}>
                                                <ExampleCanvas
                                                    structure={data} />
                                              
                                            </Card>
                                        </Grid>
                                    </>)
                                }
                            </Grid>
                            <Box sx={{display:'flex',mt:2}}>
                                <Typography variant='subtitle1' sx={{flexGrow:1}} color='text.secondary'>Click add to preview</Typography>
                            <Button variant='outlined' color='secondary' onClick={()=>updateobject({data:entry[1].path,parid:outputdata.parentnodeid,pathid:outputdata.pathid})}>Add</Button>

                            </Box>
                        </SubCard>
                    )
                } 
            </AccordionDetails>
        </Accordion> */}
       {/* } */}
    </Card>
);
}
export default TotalIncomeCard;