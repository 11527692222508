import axios from "axios";

const compoundname = async (Smile) => {
  try {
    const cidResponse = await axios.get(
      `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/smiles/${Smile}/property/IUPACName,MolecularWeight/JSON`
    );

    return ({...cidResponse.data.PropertyTable.Properties[0], smile:Smile});
  } catch (pubChemError) {
    console.error("Error with PubChem request:", pubChemError);
    return null; 
  }
};

const Elncomponents = async ({ treedata }) => {
  console.log(treedata.path);
  const OBJ = [];

  const refine1 = async (atta) => {
    const propsimle = await compoundname(atta.smile);
     const reactantsmile=[]
     atta.children.map((data) => reactantsmile.push(data.smile))

    
    const Ch = await Promise.all(
      atta.children.map(async (data) =>  await compoundname(data.smile))
    );



    OBJ.push({
      reaction:{ProductSmile:atta.smile,reactantsmiles:reactantsmile},
      Product: propsimle,
      reactants: {...Ch},
      procedure: atta.procedure[0],
    });

    // Recursively call refine1 for each child
    await Promise.all(
      atta.children.map(async (X) => {
        if (X.children !== undefined) {
          await Promise.all(
            X.children.map(async (Y) => {
              await refine1(Y);
            })
          );
        }
      })
    );
  };

  const ARR = [...treedata.path.children];

  
  await Promise.all(
    ARR.map(async (data) => {
      await refine1(data);
    })
  );

  return OBJ;
};

export default Elncomponents;
