import { IconButton,Button } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Iconstyles={
    fontSize:'1.5rem'
}




const DeleteIconButton = ({Clickfun})=>{
    return(
        <IconButton sx={{...Iconstyles,color:'red'}}onClick={Clickfun}>
            <DeleteIcon/>
        </IconButton>
    )
}
const EditIconButton = ({Clickfun})=>{
    return(
        <IconButton color='secondary' sx={{...Iconstyles}}onClick={Clickfun}>
            <EditIcon/>
        </IconButton>
    )
}
const ArchiveIconButton = ({Clickfun})=>{
    return(
        <IconButton color='secondary' sx={{...Iconstyles}}onClick={Clickfun}>
            <ArchiveIcon/>
        </IconButton>
    )
}

const DetailconButton =({Clickfun})=>{
    return(
        <IconButton color='secondary' sx={{...Iconstyles}}onClick={Clickfun}>
            <MoreVertIcon/>
        </IconButton>
    )
}




export {DeleteIconButton,EditIconButton,ArchiveIconButton,DetailconButton}