import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LanIcon from '@mui/icons-material/Lan';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const rebolt = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'utilities',
            title: 'ReBolt',
            type: 'item',
            url: '/Rebolt/Experimentsroot',
            icon: ScienceOutlinedIcon,
            sublist:[
                {Title:'Retrosynthesis',
                icon:LanIcon,
                sublist:[
                    {title:'Retrosynthesis',onclick:'Rebolt/Experimentsroot'},
                    {title:'Interactive Builder',onclick:'ibinput'}
                ]},
                {Title:'Forward Reaction',
                sublist:[
                    { title:'Forward Reaction',onclick:'ForwardReactionInput'},
            {title:'Conditions recommendation',onclick:'ConditionsRecommendationIn'},
            {title:'Impurity Prediction',onclick:'ImpurityPredictionIn'},
            {title:'Atom Mapping',onclick:'AtomMappingIn'}
                ]
            },
            {
                Title:'Model fine-tuner',
                onclick:'custommodels'
            }
            ]
        }
    ]
};

export default rebolt;
