import React from 'react'
import { Dialog,DialogContent,DialogTitle,DialogActions,Typography } from '@mui/material'
import CloseIconbutton from 'ui-component/Buttons/CloseIconbutton'

const DialogBox = ({children,closefunction,Title,content,status}) => {
  


 


  return (
    <>
    {
      children
    }
    <Dialog  open={status} fullWidth
        maxWidth={'lg'} sx={{height:'102vh', margin:'0px'}} >
        <DialogTitle sx={{padding:'2px',backgroundColor:'#edf1f7'}}>
          <div style={{width:'100%', display:'flex', justifyContent:'right'}}>
          <CloseIconbutton onClick={closefunction}/>
          </div>
          <Typography color='secondary' sx={{fontWeight:'bold',textAlign:'center',fontSize:'1.25rem',width:'100%'}}>{Title}</Typography>
        </DialogTitle>
        <DialogContent>
            <div style={{minWidth:'600px', minHeight:'500px', backgroundColor:'#edf1f7'}}>{content}</div>
            
        </DialogContent>
    </Dialog>
    </>
  )
}

export default DialogBox