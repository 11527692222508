// assets
import { LibraryBooks, LibraryBooksOutlined, SaveAlt } from '@mui/icons-material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ShareIcon from '@mui/icons-material/Share';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const project = {
    id: 'projects',
    type: 'group',
    children: [
        {
            id: 'Agents',
            title: 'Agents',
            type: 'item',
            url: '/fs/agentScreen2',
            icon: SupportAgentIcon,
            breadcrumbs: true,
        }
    ,
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: '/projects',
            icon: AddBoxOutlinedIcon,
            breadcrumbs: true,
        }
    ,
        {
            id: 'Copies',
            title: 'Saved paths',
            type: 'item',
            url: '/Copies',
            icon: SaveAlt,
            breadcrumbs: true
        }
        ,
        // {
        //     id: 'Analogs library',
        //     title: 'Analogs',
        //     type: 'item',
        //     url: '/library',
        //     icon: LibraryBooksOutlined,
        //     breadcrumbs: true,
        //     disabled:true,
        // }
        // , 
        {
            id: 'Shared Experiments',
            title: 'Shared Experiments',
            type: 'item',
            url: '/SharedExperiments',
            icon:  ShareIcon,
            breadcrumbs: true,
            
        },
        {
            id: 'Library',
            title: 'Library',
            type: 'item',
            url: '/SMLibrary',
            icon: LibraryAddOutlinedIcon,
            breadcrumbs: true,   
        },
        {
            id: 'ELN',
            title: 'ELN',
            type: 'item',
            url: '/elnprojects',
            icon: MenuBookRoundedIcon,
            breadcrumbs: true,   
        },
        {
            id: 'Optimization',
            title: 'Optimization',
            type: 'item',
            url: '/optimiztioninput',
            icon: AutoGraphIcon,
            breadcrumbs: true,   
        }


    ]
};

export default project;
