import React from 'react'
import { Dialog,DialogContent,DialogTitle,DialogActions,Typography,DialogContentText,Grid } from '@mui/material'
import CloseIconbutton from 'ui-component/Buttons/CloseIconbutton'
import { ColorButton,ColorButton2,DelButton } from 'ui-component/Buttons/ColorButton'
import { AddOutlined, ArrowBackOutlined, ArrowForwardOutlined, ArrowRight, CancelOutlined, CloseOutlined, DeleteOutlined } from "@mui/icons-material";


const DeleteWarning = ({children,DeleteOpen,closeDeleteDialog,Delfunction}) => {
  return (
    <div>
        {children}
        <Dialog open={DeleteOpen} onClose={closeDeleteDialog} >
          <DialogContent>
            <DialogContentText>
              ALERT
            </DialogContentText>
            <p>Are you sure, all the data will be permanently erased</p>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} xl={6}>
                <ColorButton2
                  onClick={closeDeleteDialog}
                  endIcon={<CancelOutlined />}
                  fullWidth={true}>
                  Cancel
                </ColorButton2>
              </Grid>
              <Grid item sm={12} md={6} xl={6}>
                <DelButton
                  sx={{backgroundColor:'red'}}
                  endIcon={<DeleteOutlined />}
                  onClick={Delfunction}
                  fullWidth={true}
                >
                  delete
                </DelButton>
              </Grid>
            </Grid>
          </DialogActions>
          </Dialog>
    </div>
  )
}

export default DeleteWarning