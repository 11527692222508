import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';

const CloseIconbutton = ({onClick}) => {
  return (
    <div style={{width:'100%',display:'flex',justifyContent:'right'}}>
        <IconButton sx={{padding:'5px'}} onClick={onClick}>
        <HighlightOffIcon  color='secondary' style={{fontSize:'1.5rem'}}/>
        </IconButton>
    </div>
  )
}

const CloseIconbutton2 = ({onClick}) => {
  return (
    <div style={{width:'100%',display:'flex',justifyContent:'right', position:'absolute'}}>
        <IconButton sx={{padding:'5px'}} onClick={onClick}>
        <HighlightOffIcon  color='secondary' style={{fontSize:'1.5rem'}}/>
        </IconButton>
    </div>
  )
}



export default CloseIconbutton
export{CloseIconbutton2}