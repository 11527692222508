import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Card, Divider, Grid, AppBar, Toolbar, Button, Typography, ImageList, ImageListItem, Alert, IconButton, Tooltip,  Snackbar, TableContainer } from '@mui/material';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import MoleculeStructure from "views/RDkit/MoleculeStructure";
import Implies from '../../../assets/images/icons/arrow2.png'
import * as XLSX from 'xlsx';

//firebase imports
import { db } from 'index';
import { query, doc, getDoc } from '@firebase/firestore';
import { Add, AddOutlined, ArrowBackOutlined, ArrowForward, ArrowForwardOutlined, CopyAllOutlined, Download } from '@mui/icons-material';
import ExampleCanvas from 'views/RDkit/ExampleCanvas';
import SubCard from 'ui-component/cards/SubCard';
import Subappbar from 'ui-component/subappbar/Subappbar';
import { useEffect } from 'react';
import { textAlign } from '@mui/system';
import { useLocation } from 'react-router-dom';

const ConditionRecomOutcomp=({eid,Agsc})=> {
    var navigate = useNavigate()
    const [arrayData, setArrayData] = React.useState([])
    const [isLoading, setLoader] = React.useState(true)
    const [Inputs,setInputs]= React.useState({})
    const [smile, setSmile] = React.useState('')
    // const { eid } = useParams();
    const [smilesCopysnack,setsmilesCopysnack] = React.useState(false)
    const [Proid, setProid] =React.useState('')
    const [flowRedirect,setflowRedirect] = React.useState(false)
    
    const Loc = useLocation()
    const Smilecard =({structure,clckfunction})=>{
       return( 
       
       <div>
       {structure!='' &&
       <div>
            <div style={{textAlign:'right', height:'fit-content',zIndex:10}}>
            <Tooltip title='copy text' sx={{display:'absolute', borderRadius:'100%'}} >
                               <IconButton onClick={clckfunction}>
                 <CopyAllOutlined sx={{width:'15px'}} />
               </IconButton>
            </Tooltip>
            </div>
            <div style={{position:'relative',display:'flex', justifyContent:'center'}}>
            <MoleculeStructure width={100} height={75} svgMode structure={structure}/>
            </div>
        </div>
        }
        </div>
    )}
    
    
    const getoutput = () => {
       
        const Document = query(doc(db, 'condition_recommendation', eid))
        getDoc(Document)
            .then(res => {
                console.log(res)
                setProid(res.data().project_id)
               setInputs({Reactant:res.data().reactant,Product:res.data().product})
               const Temparr = []
               Object.values(res.data().output).forEach((data)=>{
                Temparr.push(data)
               })
               setArrayData(Temparr)
               console.log(Temparr)
                //console.log(arrayData)
                setLoader(false)
            })


    }


    const DwnldFun = ()=>{
        
      const Data = [ ['SNO','Catalyst','Reagent 1','Reagent 2','Solvent 1','Solvent 2'],]
     arrayData.map((data,index)=>{
        Data.push([index+1,data.catalyst,data.reagent1,data.reagent2,data.solvent1,data.solvent2])
     })
    
   console.log(Data)
  
 
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(Data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Rebolt/conditionsrecommendation.xlsx');
    }
    

    console.log()
    useEffect(() => {
        if(Loc.state?.flow){
            setflowRedirect(true)
        }
        getoutput();
    }, [])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.Font,
            padding:'10px',
            textAlign:'center'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding:'10px',
            textAlign:'center'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const iconstyles = {
        color: 'white'
    }
    const iconstyles2 = {
        color: "#323259"
    }


   const clckfunction = (sml)=>{
    navigator.clipboard.writeText(sml);
    setsmilesCopysnack(true)
    }

    
    return (
        <>
            
                <Subappbar
                navigate1={flowRedirect ?   () => navigate(-1):() => navigate( `/Experiments/${Proid}`,{state:{template:'condition_recommendation'}})}
                navigate3={() => navigate('/ConditionsRecommendationIn')}
                icon1={!Agsc && <ArrowBackOutlined sx={iconstyles2} />}
                crexpicon={!Agsc && 'New Experiment'}
                title="Conditions Recommendation"
            />
            
            
            <MainCard>
            <AppBar position="static" sx={{ marginBottom:'30px', backgroundColor: 'white' }}>
            <Toolbar sx={{flexDirection: 'row-reverse'}}>           
            <Tooltip title='Download'>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    // onClick={() => {setDwndiag(true) }}
                                    sx={{position:'absolute'}}
                                    onClick={DwnldFun}
                                    
                                >
                                    <Download sx={iconstyles2} />
                                </IconButton>
            </Tooltip>
            </Toolbar> 
            </AppBar>
                <Paper>
                    <Card>
                        {isLoading ?
                            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="secondary" />
                            </Stack>


                            :
                            <>
                                {arrayData.length === 0 ?
                                    <Alert severity="error" sx={{ textAlign: 'center' }}>sorry we are unable to recommend paths for the given molecule.</Alert>
                                    :
                                    <>
                                    <div style={{display:'flex',justifyContent:'space-around', marginBottom:'20px'}}>
                                          <MoleculeStructure width={150} height={100} svgMode structure={Inputs.Reactant}/>
        
                                          <img src={Implies} style={{width:'150px',height:'30px', marginTop:'35px'}}/>
                                          
                                          <MoleculeStructure width={150} height={100} svgMode structure={Inputs.Product}/>
                                    </div>
                                    <TableContainer sx={{ minHeight: '60vh', maxHeight:'60vh' }} elevation='4'>
                                        <Table stickyHeader = {true}>
                                            <TableHead >
                                                <TableRow sx={{padding:'10px',boxShadow:'0px 1px 5px 0px #323259'}}>
                                                    <StyledTableCell>Sno</StyledTableCell>
                                                    <StyledTableCell>Catalyst</StyledTableCell>
                                                    <StyledTableCell >Reagent 1</StyledTableCell>
                                                    <StyledTableCell >Reagent 2</StyledTableCell>
                                                    <StyledTableCell>Solvent 1</StyledTableCell>
                                                    <StyledTableCell>Solvent 2</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {
                                            arrayData.map((data,index)=>{

                                            return(
                                            <TableRow  key={index}>
                                                    <StyledTableCell>{index+1}</StyledTableCell>
                                                    <StyledTableCell><Smilecard structure={data.catalyst} clckfunction={()=>clckfunction(data.catalyst)} /></StyledTableCell>
                                                    <StyledTableCell><Smilecard structure={data.reagent1} clckfunction={()=>clckfunction(data.reagent1)} /></StyledTableCell>
                                                    <StyledTableCell><Smilecard structure={data.reagent2} clckfunction={()=>clckfunction(data.reagent2)} /></StyledTableCell>
                                                    <StyledTableCell><Smilecard structure={data.solvent1}clckfunction={()=>clckfunction(data.solvent1)} /></StyledTableCell>
                                                    <StyledTableCell><Smilecard structure={data.solvent2} clckfunction={()=>clckfunction(data.solvent12)} /></StyledTableCell>
                                            </TableRow>
                                            )
                                            })
                                            }
                                            </TableBody>
                                        </Table>
                                        </TableContainer>
                                    </>

                                }

                            </>
                        }

                    </Card>
                </Paper>
            </MainCard>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={smilesCopysnack} autoHideDuration={2000} onClose={() => { setsmilesCopysnack(false) }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                    <Alert onClose={() => setsmilesCopysnack(false)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
                        SMILES copied to clipboard
                    </Alert>
                </Snackbar>
            </Stack>
            
        </>
    );
}

export default ConditionRecomOutcomp;

