import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Chip,Card, Divider, Grid, AppBar, Toolbar, Button, Typography, ImageList, ImageListItem, Alert, IconButton, Tooltip,  Snackbar } from '@mui/material';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { auth, db, uid } from "index"
import { doc, collection, deleteDoc,addDoc,setDoc,serverTimestamp, query,updateDoc,arrayUnion,arrayRemove, where,FieldValue, onSnapshot,getDoc,getDocs,collectionGroup} from '@firebase/firestore';


//firebase imports
import { Add, AddOutlined, ArrowBackOutlined, ArrowForward, ArrowForwardOutlined, CopyAllOutlined } from '@mui/icons-material';
import ExampleCanvas from 'views/RDkit/ExampleCanvas';
import MoleculeStructure from 'views/RDkit/MoleculeStructure';
import SubCard from 'ui-component/cards/SubCard';
import Subappbar from 'ui-component/subappbar/Subappbar';
import { useEffect } from 'react';
import axios from 'axios';
import { indexOf } from 'lodash';

const ForwardReactionOutputComp=({eid,Agsc})=> {
    var navigate = useNavigate()
    const [arrayData, setArrayData] = React.useState([])
    const [Expdata,setExpdata] =React.useState({})
    const [isLoading, setLoader] = React.useState(true)
    const [ReactantSmile, setReactant] = React.useState('')
    const [smile, setSmile] = React.useState('')
    // const { eid } = useParams();
    const [smilesCopysnack,setsmilesCopysnack] = React.useState(false)
    const [token,settoken] = React.useState('')


   const theme = useTheme()

    

   const funcc = async () => {
   
    settoken(await auth.currentUser.getIdToken())
    
  }
  const getoutput = async() => {
    const docRef = doc(db, 'forward_reaction', eid)
     await onSnapshot(docRef, async(res) => {
            setExpdata(res.data())
            console.log(res.data().output.predictions)
            const DataArry = await Promise.all(res.data().output.predictions.map(async(data) => {
                const Dobj = { props: data };
                if (data.AMid != undefined) {
                    console.log('ran')
                    const amid = await getDoc(doc(db, 'atommapping', data.AMid));
                    Dobj.amidstatus = amid?.data().status;
                }
                if (data.IPid != undefined) {
                    console.log('ran')
                    const Imid = await getDoc(doc(db, 'impurities', data.IPid));
                    Dobj.IPidstatus = Imid?.data().status;
                }
                if (data.CRid != undefined) {
                    console.log('ran')
                    const Crid = await getDoc(doc(db, 'condition_recommendation', data.CRid));
                    Dobj.CRidstatus = Crid.data().status;
                }
                console.log(Dobj);
                return Dobj;
            }));
            console.log(DataArry);
            setArrayData(DataArry);
            setReactant(res.data().output.reactants);
            setSmile(`data:image/jpeg;base64,${res.data().output.reacts_b64}`);
            console.log(arrayData);
            setLoader(false);
        })
}

console.log(auth.currentUser.uid)

    useEffect(() => {
        funcc()
        getoutput();
    }, [])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            textAlign:'center',
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.Font,
            padding:'10px'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding:'10px'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#501f84'),
            backgroundColor: '#501f84',
            '&:hover': {
              backgroundColor: '#7030b3',
            },
      }));
    const iconstyles = {
        color: 'white'
    }
    const iconstyles2 = {
        color: "#323259"
    }
console.log(Expdata)
console.log(token)
     const AMsubmit =async ({reactant,prods,id, })=>{
         await addDoc(collection(db, "atommapping"), {
            expname: `${Expdata.expname}_AM`,
            status:'pending',
            reactant: Expdata.output.reactants,
            product: prods,
            project_id: Expdata.project_id,
            createdAt: serverTimestamp(),
            uid: auth.currentUser.uid,
          })
          .then(async(res)=>{
            const Docref = doc(db,'forward_reaction',eid)
            const OrgData = await getDoc(Docref)
            const rqdata = (OrgData.data().output.predictions)
            rqdata[id].AMid= res.id
            console.log(rqdata)

            const fref = "predictions"; 
            const fieldValue = rqdata; 
            
            const updateObject = {
                [`output.${fref}`]: fieldValue
            };

           await updateDoc(Docref,updateObject,{'merge':true})
           .then(async(resp)=>  {
                await axios.post('https://reboltapis.boltchem.com/v1/retrosynthesis/atommapping', {
                "experiment_id": res.id,
                "reaction": `${Expdata.output.reactants}>>${prods}`,
                "tokenid": token,
              }).then((respp)=>{console.log(respp)})
           })
          }
          )
          
          
        }
        const CRsubmit =async ({reactant,prods,id, })=>{
            await addDoc(collection(db, "condition_recommendation"), {
               expname: `${Expdata.expname}_CR`,
               status:'pending',
                reactant: Expdata.output.reactants,
                product: prods,
                project_id: Expdata.project_id,
                "max_recommendations": "10",
                createdAt: serverTimestamp(),
                uid: auth.currentUser.uid,
             })
             .then(async(res)=>{
               const Docref = doc(db,'forward_reaction',eid)
               const OrgData = await getDoc(Docref)
               const rqdata = (OrgData.data().output.predictions)
               rqdata[id].CRid= res.id
               console.log(rqdata)
   
               const fref = "predictions"; 
               const fieldValue = rqdata; 
               
               const updateObject = {
                   [`output.${fref}`]: fieldValue
               };
   
              await updateDoc(Docref,updateObject,{'merge':true})
              .then(async(resp)=>  {
                   await axios.post('https://reboltapis.boltchem.com/v1/retrosynthesis/conditions', {
                   "experiment_id": res.id,
                   "product": prods,
                   "reactants": Expdata.output.reactants,
                   "tokenid": token,
                   "max_recommendations": "10"
                 }).then((respp)=>{console.log(respp)})
              })
             }
             )
             
             
           }


           const IPsubmit =async ({reactant,prods,id, })=>{
            addDoc(collection(db, "impurities"), {
                expname: `${Expdata.expname}_IP`,
                status:'pending',
                reactant: Expdata.output.reactants,
                product: prods,
                "solvents": "",
                "reagents": "",
                project_id:Expdata.project_id,
                "max_recommendations": "10",
                createdAt: serverTimestamp(),
                uid: auth.currentUser.uid,
              })
             .then(async(res)=>{
               const Docref = doc(db,'forward_reaction',eid)
               const OrgData = await getDoc(Docref)
               const rqdata = (OrgData.data().output.predictions)
               rqdata[id].IPid= res.id
               console.log(rqdata)
   
               const fref = "predictions"; 
               const fieldValue = rqdata; 
               
               const updateObject = {
                   [`output.${fref}`]: fieldValue
               };
   
              await updateDoc(Docref,updateObject,{'merge':true})
              .then(async(resp)=>  {
                   await axios.post('https://reboltapis.boltchem.com/v1/retrosynthesis/impurityPrediction', {
                    "experiment_id": res.id,
                    "reactants": Expdata.output.reactants,
                    "product": prods,
                    "solvents": "",
                    "reagents": "",
                    "tokenid": token
                 }).then((respp)=>{console.log(respp)})
              })
             }
             )
             
             
           }
          
          
     

    console.log(arrayData)













    const DataTable = () => {

        var sno = 0
        return (
            arrayData.map(each => {
               
                sno += 1
                const smileImg = `data:image/jpeg;base64,${each.props.smiles_b64}`
                return (
                    <TableRow sx={{height:'100%'}} >
                        <TableCell sx={{textAlign:'center',height:'100%'}}>{sno}</TableCell>
                        <TableCell sx={{display:'flex', justifyContent:'center', position:'relative', flexDirection:'column',height:'100%'}}>
                        <div  style={{ width:'180px', display:'flex', justifyContent:'flex-end', width:'100'}}>
                        <Tooltip title='copy text' sx={{display:'absolute'}} >
                                <IconButton onClick={() => {
                                    navigator.clipboard.writeText(each.props.SMILES);
                                    setsmilesCopysnack(true)
                                }}>
                                    <CopyAllOutlined sx={iconstyles2} />
                                </IconButton>
                            </Tooltip></div>
                        <ExampleCanvas structure={each.props.SMILES} />
                        
                        </TableCell>
                        <TableCell sx={{height:'100%'}} >
                            <div style={{marginLeft:'30%'}}>
                            <Typography variant='body' component='p'>Lipinkski : {each.props.Lipinski=='yes'? "Yes" : "No"}</Typography>
                            <Typography variant='body' component='p'>Molecular weight: {(each.props.Mol_Wt).toFixed(2)}</Typography>
                            <Typography variant='body' component='p'>Stereo centers : {each.props.StereoCenters} </Typography>
                            <Typography variant='body' component='p'>SA score : {(each.props.SA).toFixed(2)} </Typography>
                            <Typography variant='body' component='p'>Feasibility score : {each.props.feasibility_scores} </Typography>
                            </div>
                        </TableCell>
                       {!Agsc &&  <TableCell>{
                           each.amidstatus == undefined ?
                            <ColorButton onClick={()=>AMsubmit({prods:each.props.SMILES, id:arrayData.indexOf(each)})}>
                                <ArrowForwardOutlined/>
                            </ColorButton>: each.amidstatus=='completed'? 
                            
                            <Button variant='outlined' onClick={()=>{navigate(`/atommapping/${each.props.AMid}`,{state:{flow:'true'}})}}>view</Button>:<Chip variant='outlined' label='Pending' color='primary' />
                           
                        }
                       
                        </TableCell>}
                        {!Agsc && <TableCell>{
                            each.CRidstatus==undefined ?
                            <ColorButton onClick={()=>CRsubmit({prods:each.props.SMILES, id:arrayData.indexOf(each)})}>
                                <ArrowForwardOutlined/>
                            </ColorButton> : each.CRidstatus=='completed'? 
                            
                            <Button variant='outlined' onClick={()=>{navigate(`/condition_recommendation/${each.props.CRid}`,{state:{flow:'true'}})}}>view</Button>:<Chip variant='outlined' label='Pending' color='primary' />
                        }
                       
                        </TableCell>}
                        {!Agsc &&  <TableCell>{
                            each.IPidstatus==undefined ?
                            <ColorButton onClick={()=>IPsubmit({prods:each.props.SMILES, id:arrayData.indexOf(each)})}>
                                <ArrowForwardOutlined/>
                            </ColorButton> : each.IPidstatus=='completed'? 
                            
                            <Button variant='outlined' onClick={()=>{navigate(`/impurities/${each.props.IPid}`,{state:{flow:'true'}})}}>view</Button>:<Chip variant='outlined' label='Pending' color='primary' />
                        }
                       
                        </TableCell>}
                    </TableRow>
                )
            })
          
        )
    }

    return (
        <>
             <Subappbar
                navigate1={() => navigate(`/Experiments/${Expdata.project_id}`,{state:{template:'forward_reaction'}})}
                navigate2={() => navigate(-1)}
                navigate3={() => navigate('/Rebolt/Experimentsroot')}
                icon1={!Agsc &&<ArrowBackOutlined sx={iconstyles2} />}
                icon2={!Agsc &&<ArrowForwardOutlined sx={iconstyles2} />}
                crexpicon={!Agsc &&<AddOutlined sx={iconstyles2} />}
                title="Forward Reaction"
            />
            <MainCard>
                <Paper >
                    <Card  sx={{overflowX:'scroll'}} >
                        {isLoading ?
                            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="secondary" />
                            </Stack>


                            :
                            <>
                                {arrayData.length === 0 ?
                                    <Alert severity="error" sx={{ textAlign: 'center' }}>sorry we are unable to recommend paths for the given molecule.</Alert>
                                    :
                                    <>
                                        <SubCard>
                                            <Grid container spacing={2}>
                                                <Grid item xl={12} md={12} sm={12} xs={12}
                                                    sx={{ alignItems: 'center', textAlign: 'center',display:'flex', justifyContent:'center', fontSize:'20px' }}>
                                                    <Typography variant='subtitle1' component='div'  sx={{fontSize:'20px'}}>Reactants</Typography>

                                                    <Tooltip title='copy smile'>
                                                        <IconButton onClick={() => {
                                                            navigator.clipboard.writeText(ReactantSmile);
                                                            setsmilesCopysnack(true)
                                                        }}>
                                                            <CopyAllOutlined sx={iconstyles2} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Divider variant='fullWidth' sx={{ marginTop: '10px' }} />

                                                </Grid>
                                                <Divider />
                                                <Grid item xl={12} md={12} sm={12} xs={12}
                                                    sx={{ alignItems: 'center', textAlign: 'center', display:'flex', justifyContent:'center', flexWrap:'wrap' }}>
                                                      {
                                                        ReactantSmile.split('.').map((data)=>
                                                    <MoleculeStructure structure={data}  width={100}  height={50} svgMode/> 
                                                        )
                                                }
                                                    

                                                </Grid>
                                            </Grid>
                                        </SubCard>


                                        <Table >
                                            <TableHead >
                                                <TableRow sx={{padding:'10px',boxShadow:'0px 1px 5px 0px #323259'}}>
                                                    <StyledTableCell sx={{textAlign:'center'}}>Sno</StyledTableCell>
                                                    <StyledTableCell sx={{textAlign:'center'}}>Structure</StyledTableCell>
                                                    <StyledTableCell sx={{textAlign:'center'}}>Properties</StyledTableCell>
                                                    {!Agsc && <StyledTableCell sx={{textAlign:'center', width:'12%'}}>Atom Mapping</StyledTableCell>}
                                                    {!Agsc && <StyledTableCell sx={{textAlign:'center', width:'12%'}}>condition Recommendation</StyledTableCell>}
                                                    {!Agsc && <StyledTableCell sx={{textAlign:'center', width:'12%'}}>Impurity Prediction</StyledTableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <DataTable />
                                            </TableBody>
                                        </Table>

                                    </>

                                }

                            </>
                        }

                    </Card>
                </Paper>
            </MainCard>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={smilesCopysnack} autoHideDuration={2000} onClose={() => { setsmilesCopysnack(false) }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                    <Alert onClose={() => setsmilesCopysnack(false)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
                        SMILES copied to clipboard
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}

export default ForwardReactionOutputComp;

