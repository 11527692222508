import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Card, Divider, Grid, AppBar, Toolbar, Button, Typography, ImageList, ImageListItem, Alert, IconButton, Tooltip,  Snackbar, TableContainer } from '@mui/material';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import MoleculeStructure from "views/RDkit/MoleculeStructure";
import Implies from '../../../assets/images/icons/arrow2.png'
import Plus from '../../../assets/images/icons/plus.png'
import { useTheme } from '@mui/material';

//firebase imports
import { db } from 'index';
import { query, doc, getDoc } from '@firebase/firestore';
import { Add, AddOutlined, ArrowBackOutlined, ArrowForward, ArrowForwardOutlined, CopyAllOutlined, Download } from '@mui/icons-material';
import ExampleCanvas from 'views/RDkit/ExampleCanvas';
import SubCard from 'ui-component/cards/SubCard';
import Subappbar from 'ui-component/subappbar/Subappbar';
import { useEffect,useState } from 'react';
import { textAlign } from '@mui/system';
import { useLocation } from 'react-router-dom';

const AtommappingOutputcomponent=({eid,Agsc})=> {
    var navigate = useNavigate()
    const [Data,setData] = useState({})
    const [smilesCopysnack,setsmilesCopysnack] = React.useState(false)
    const [isLoading, setLoader] = React.useState(true)
    // const { eid } = useParams();
    const [flowRedirect,setflowRedirect] = React.useState(false)
    
   
    const Loc = useLocation()
    
    const theme = useTheme()
    
    const getoutput = () => {
       
        const Document = query(doc(db, 'atommapping', eid))
        getDoc(Document)
            .then(res => {
                 setData({...res.data()})
               })
                //console.log(arrayData)
                setLoader(false)
            }

    console.log()
    useEffect(() => {
        getoutput();
        if(Loc.state?.flow){
            setflowRedirect(true)
        }
    }, [])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.Font,
            padding:'10px'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding:'10px'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const iconstyles = {
        color: 'white'
    }
    const iconstyles2 = {
        color: "#323259"
    }
 console.log(theme.palette)
 console.log(Data?.output?.mapped_rxn.split('>>')[0].split('.'))   
    return (
        <>
             <Subappbar
                navigate1={flowRedirect ?   () => navigate(-1):() => navigate( `/Experiments/${Data.project_id}`,{state:{template:'atommapping'}})}
                navigate3={() => navigate('/AtomMappingIn')}
                icon1={!Agsc &&<ArrowBackOutlined sx={iconstyles2} />}
                crexpicon={!Agsc ?'New Experiment':undefined}
                title="Atom Mapping"
            />
            
            <MainCard>
                <Paper>
                    <Card >
                        {Data.length<=0 ?
                            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="secondary" />
                            </Stack>


                            :
                            <div className='Chatscroll' style={{marginTop:'10px', overflow:'scroll'}}>
                            <Typography sx={{marginBottom:'20px',color:'#323259'}}>Reaction :</Typography>
                            <div style={{display:'flex',justifyContent:'space-around', marginBottom:'20px',flexWrap:'wrap', marginLeft:'10px',alignItems:'center'}}>
                                    <div style={{display:'flex',flexWrap:'wrap',width:'50%',}}>
                                    {
                                    Data?.reactant?.split('.').map((rec,index)=>
                                    
                                    
                                    <div style= {{display:'flex' , marginBottom:'30px'}}>
                                    <MoleculeStructure width={100} height={80} svgMode structure={rec} />
                                     {(index < Data?.reactant?.split('.').length-1) &&
                                        <img  src={Plus} width='10px' height='10px' alt='plus'style= {{position:'relative',top:'40px',marginLeft:'20px', marginRight:'20px'}}/> }
                                    </div>
                                    )
                                    }
                                    </div>
                                  <img src={Implies} width='50px' height='30px' alt ='implies' style= {{position:'relative'}}/> 
                                   <div  style={{width:'25%'}}><MoleculeStructure width={150} height={100} svgMode structure={ Data?.product}/></div>
                                   </div>

                                <Typography sx={{marginBottom:'20px',color:'#323259'}}>Atom Mapped Reaction:</Typography>
                                    <div style={{display:'flex',justifyContent:'space-around', marginBottom:'20px',flexWrap:'wrap', marginLeft:'10px',alignItems:'center'}}>
                                    <div style={{display:'flex',flexWrap:'wrap',width:'50%',}}>
                                    {
                                    Data?.output?.mapped_rxn.split('>>')[0].split('.').map((rec,index)=>
                                    
                                    
                                    <div style= {{display:'flex' , marginBottom:'30px'}}>
                                    <MoleculeStructure width={100} height={80} svgMode structure={rec} />
                                     {(index < Data.output.mapped_rxn.split('>>')[0].split('.').length-1) &&
                                        <img  src={Plus} width='10px' height='10px' alt='plus'style= {{position:'relative',top:'40px',marginLeft:'20px', marginRight:'20px'}}/> }
                                    </div>
                                    )
                                    }
                                    </div>
                                  <img src={Implies} width='50px' height='30px' alt ='implies' style= {{position:'relative'}}/> 
                                   <div  style={{width:'25%'}}><MoleculeStructure width={150} height={100} svgMode structure={ Data?.output?.mapped_rxn.split('>>')[1]}/></div>
                                   </div>

                            </div>
                        }

                    </Card>
                </Paper>
            </MainCard>
            
        </>
    );
}

export default AtommappingOutputcomponent;

