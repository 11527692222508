import { ArrowBackOutlined, ArrowForwardOutlined, EditOutlined } from '@mui/icons-material';
import { AppBar, Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { textAlign } from '@mui/system';
import React from 'react'

const Subappbar=({
    title,
    navigate1,
    navigate2,
    navigate3,
    sxterm,
    icon1,
    icon2,
    crexpicon,
    editcopy,
    tooltip1,
    searchbar,
    Switch
})=>{

    const appbarstyles={
        backgroundColor:'#fff',
        color:'#323259',
        marginBottom:'10px',
        ...sxterm
    }
    const toolbarstyles={
        display:'flex',
        textAlign:'left'
    }
    const toolbartitlestyles={

        color:'#323259',
        flexGrow:1,
        fontWeight:500,
        fontSize:'.9375rem',
        lineHeight: '1.5rem',
        display:'flex'
    }
    return(
        <>
        <AppBar position='static' sx={appbarstyles} elevation={0}>
            <Toolbar variant='dense' sx={toolbarstyles}>
                <IconButton onClick={navigate1} sx={{color:"#323259"}}>
                    {icon1}
                </IconButton>
                <IconButton onClick={navigate2} sx={{color:"#323259"}}>
                    {icon2}
                </IconButton>
                <Typography variant='h3' component='h3' sx={toolbartitlestyles}>{title}</Typography>
                 
                 {
                    
                    searchbar
                 }
                {
                    crexpicon &&  
                    <Tooltip title={tooltip1}>
                
                <Button  onClick={navigate3} variant='outlined' sx={{color: "#323259",borderColor:"#323259"}} >{crexpicon}</Button>
        
                </Tooltip>}
                {
                    editcopy && 
                    <Tooltip title="edit experiment">
                    <IconButton  sx={{color:'#323259'}}>
                        <EditOutlined />
                    </IconButton>
                    </Tooltip>
                }
                {
                    Switch && 
                    <div>
                    {
                        Switch
                    }
                    </div>
                }

            </Toolbar>
        </AppBar>
        </>
    )

}

export default Subappbar;