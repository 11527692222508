
import React from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description'; 
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; 
import ImageIcon from '@mui/icons-material/Image'; 
import TextSnippetIcon from '@mui/icons-material/TextSnippet'; 

const FileDisplay = ({ fileName, onRemove, Removebutton }) => {

  // Function to get the file logo based on the file extension
  const getFileLogo = (extension) => {
    switch (extension) {
      case 'pdf':
        return <PictureAsPdfIcon sx={{ fontSize: 30, color:'red' }} />;
      case 'doc':
      case 'docx':
        return <DescriptionIcon sx={{ fontSize: 30, color:'blue' }} />;
      case 'jpg':
      case 'jpeg':
      case 'csv':
        return <DescriptionIcon sx={{ fontSize: 30, color:'green' }} />;
      case 'png':
        return <ImageIcon sx={{ fontSize: 30 , color:'green'}} />;
      case 'txt':
        return <TextSnippetIcon sx={{ fontSize: 30, color:'orange' }} />; 
      default:
        return <InsertDriveFileIcon sx={{ fontSize: 30, color:'orange'}} />; 
    }
  };

  // Extract file extension and name without the extension
  const fileExtension = fileName.split('.').pop();
  const fileBaseName = fileName.substring(0, fileName.lastIndexOf('.')); // Get the file name without extension

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', marginBottom: '1%', paddingLeft:'1%'}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          border: Removebutton?'1px solid #ccc':'none',
          borderRadius: '4px',
          width: '130px', // Set the width of the file display
          position: 'relative',
          overflow:'hidden',
        }}
      >
        {getFileLogo(fileExtension)}
        <Typography variant="body1" sx={{ color:'#323259',marginLeft: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',maxWidth: '160px'}}>
          {fileBaseName}
        </Typography>
      </Box>
      {
        Removebutton &&
      <IconButton
        size="small"
        onClick={onRemove}
        sx={{
          position: 'absolute',
          top: '-16px',
          right: '-16px', 
          borderRadius: '50%',
        }}
      >
        <CancelIcon
          sx={{
            color: 'black',
            fontSize: 20,
          }}
        />
      </IconButton>
      }
    </Box>
  );
};

export default FileDisplay;
