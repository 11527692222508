import React from 'react'
import {Button, Typography}  from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { display, fontSize, fontWeight } from '@mui/system';






const Chattoolbarcomp = ({editfun,delfun}) => {



const Butstyles={
  display: 'flex',
  justifyContent:'flex-start',
  color:'#323259',
  fontSize:'1.5rem'
}

const Icostyles={
  marginRight:'5px',
  fontSize:'1.5rem',
  color:'#323259'
}
const fontstyle={
  fontSize:'1rem',
  fontWeight:'500'
}









  return (
    <div style={{display:'flex',flexDirection:'column', background:'#efeef4fc', borderRadius:'5px'}}>
       <Button onClick={editfun}  style={Butstyles}>
        <EditIcon sx={Icostyles}/>
        <Typography sx={fontstyle}>Rename</Typography>
       </Button>
       <Button onClick={delfun} style={Butstyles}>
        <DeleteIcon  sx={Icostyles}/>
         <Typography sx={fontstyle} >Delete</Typography>
       </Button>
    </div>
  )
}

export default Chattoolbarcomp