import { styled } from '@mui/material/styles';
import { IconButton,Button } from "@mui/material"
import { useTheme } from '@mui/material/styles';




const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#323259'),
        backgroundColor: '#323259',
        '&:hover': {
          backgroundColor: '#323259',
        },
  }));

  const ColorButton2 = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#0095ff'),
    backgroundColor:"black",
    '&:hover': { backgroundColor: '#000000bd'}
  }));

  const DelButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor:"red",
    '&:hover': { backgroundColor: '#ff0000a8'}
  }));


  export {ColorButton,ColorButton2, DelButton}